import React, { Component } from 'react';  
import {Link} from "react-router-dom";
import axios from "axios";
import moment from "moment";
import swal from 'sweetalert';

require('moment/locale/pt-br');
require('moment/locale/en-au');
require('moment/locale/es');

moment.locale('pt-br');
class Post extends Component {

    constructor(props){

        super(props);

        this.getDataPost = this.getDataPost.bind(this);
        this.state = {load:false};
        window.post = this;

    }
    
    componentDidMount(){

        this.getDataPost();

    }

    removePost(){

       
        swal({
            title: "Tem certeza?",
            text: "Os dados não podem ser recuperados",
            icon: "warning",
            dangerMode: true, 
            buttons: ["Não cancelar", "Sim"],
          })
          .then(willDelete => {
            if (willDelete) {
            

                 // Make a request for a user with a given ID
                axios.delete(window.url+'/post/'+this.props.match.params.id).then(function(response){

                    this.props.history.push('/');     
                    //console.log(response.data);

                }.bind(this)).catch(function(error){ console.log(error); });

            }
          });

    }
    

    async getDataPost(){

        // Make a request for a user with a given ID
        await axios.get(window.url+'/post/'+this.props.match.params.id).then(function(response){

            this.setState({post:response.data,load:true});

        }.bind(this)).catch(function(error){ console.log(error); });

    }

    getDateCategorie(){
 
        var index = this.props.state.menu.map(function(x) {return x.id; }).indexOf(parseInt(this.state.post.categorie));
        return this.props.state.menu[index];

    }

    render(){

        if(this.state.load === true){

            var menu = this.getDateCategorie();

            var col;

            if(this.state.post.file !== null && this.state.post.file !== ""){

                col = "col-md-8"; 

            }else{

                col = "col-md-12"; 

            }

        }
                
        return(

            <React.Fragment>
              
                {

                    (this.state.load === true) &&
                    <React.Fragment>

                            <div className="page-title black animated fadeIn">
                                <div className="page-title-bg editable-alpha" data-alpha="40" style={{opacity:"0.4"}}><img src={menu.background} alt=""/></div>
                                <div className="content padded dark">
                                    <div className="top-bar">
                                    <div className="pull-left">
                                            <Link to={'/'} className="btn btn-default btn-pull-up"><i className="icon-before fa fa-chevron-left"></i>Página principal</Link>
                                        </div>

                                        {

                                            (window.admin !== undefined) &&
                                            <React.Fragment>
                                                <div className="pull-left" style={{marginLeft:"5px"}}>
                                                    <Link onClick={ () => {  } } to={'/newpost'} className="btn btn-default btn-pull-up"><i className="icon-before fa fa-plus"></i>Novo post</Link>
                                                </div>
                                            </React.Fragment>


                                        } 

                                        {

                                            (this.state.load === true && window.admin !== undefined) &&
                                            <React.Fragment>

                                                <div className="pull-left" style={{marginLeft:"5px"}}>
                                                    <Link to={'/editpost/'+this.state.post.id} className="btn btn-default btn-pull-up"><i className="icon-before fa fa-pencil"></i>Editar post</Link>
                                                </div>
                        
                                                <div className="pull-right">
                                                    <div className="pull-left" style={{marginLeft:"5px"}}>
                                                        <Link onClick={ () => this.removePost() } className="btn btn-default btn-pull-up"><i className="icon-before fa fa-trash-o"></i>Remover post</Link>
                                                    </div>
                                                </div>

                                            </React.Fragment>

                                        }
                                      

                                    </div>
                                    <div className="padded-vertical">
                                        <span class="post-date-alt">{ moment(this.state.post.date,'YYYY-MM-DD').format('DD MMM YYYY') }</span>
                                        <h1 className="short">{this.state.post.name}</h1> 
                                    </div>
                                </div>
                            </div>

                            <div class="  ">
                    
                                <div class="row postf" >
                                
                                    <div class={col} style={{background:'#fff'}} dangerouslySetInnerHTML={{__html:this.state.post.html}}>
                                    </div>

                                    {

                                        (this.state.post.file !== null && this.state.post.file !== "") &&
                                        <React.Fragment>
                                            <div class="col-md-4">

                                                <div className="fileToDownload">
                                                    
                                                    <div className="contentImage">

                                                        <div className="contetDown">

                                                            <center>

                                                                <img alt="..." src={window.url+this.state.post.data_file.url} />

                                                            </center>

                                                        </div>
                                                        
                                                        <center>
                                                            <a href={window.url+'/download/'+this.state.post.data_file.id} style={{marginTop:"40px"}} type="button" class="btn btn-primary btn-sm"><i style={{color:"#fff"}} className="fa fa-download" aria-hidden="true"></i> Download</a>
                                                        </center>

                                                    </div>

                                                        

                                                </div>

                                            </div>
                                        </React.Fragment>

                                    }
                                    
                                
                                </div>  
                                    
                            </div>

                    </React.Fragment>

                }
  
            </React.Fragment>
            
          );

    }

}


export default Post;
