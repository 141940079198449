import React, { Component } from 'react';  
import {Link} from "react-router-dom";
import $ from "jquery";
import axios from "axios";

class Categorie extends Component {

    constructor(props){

        super(props);
        this.state = {error:"",file:""};
        this.post = this.post.bind(this);
        this.postFile = this.postFile.bind(this);
        
        window.newpost = this;

    }

    componentDidMount(){

        if(this.props.match.params.id !== undefined){

            this.getDataPost();

        }

    }

    async getDataPost(){


         // Make a request for a user with a given ID
         await axios.get(window.url+'/post/'+this.props.match.params.id).then(function(response){

            var post = response.data;

            console.log(post);

            Object.keys(post).forEach(function(k){
               

                if(k !== "data_cover" && k !== "data_file" && k !== "date"){

                    if($("#"+k).length === 1){
                        
                        console.log(k + ' - ' + post[k]);
                        $("#"+k).val(post[k]);
                        

                    }
                      

                }
                

            });

        }).catch(function(error){ console.log(error); });


    }

    async post(){
        
        if(this.props.match.params.id !== undefined){ this.put(); return false; }

        await axios.post(window.url+'/post',this.dataForm()).then(function(response){

            this.props.history.push('/post/'+response.data.post.id);
      
        }.bind(this)).catch(function(error){ this.setState({error:error.response.data.msg}); }.bind(this));

    }
    
    async put(){

        await axios.put(window.url+'/post/'+this.props.match.params.id,this.dataForm()).then(function(response){

            this.props.history.push('/post/'+response.data.post.id);
      
        }.bind(this)).catch(function(error){ this.setState({error:error.response.data.msg}); }.bind(this));


    }

    dataForm(){

        var data = $("#formPost").serializeArray();
        console.log(data);

        var datas = {};
        
        for (let index = 0; index < data.length; index++) {
            
            datas[data[index].name] = data[index].value;
            
        }

        return datas;

    }

    async postFile(){
        
        var formData = new FormData();

        var imagefile = document.querySelector('#filea');
        formData.append("files", imagefile.files[0]);

        //var post = await axios.post(window.url+'/upload', formData, { headers: {'Content-Type': 'multipart/form-data'}});
        //

        await axios.post(window.url+'/upload',formData,{ headers: {'Content-Type': 'multipart/form-data'}}).then(function(response){

            console.log(response.data);
            this.setState({error:"",file:response.data});
      
        }.bind(this)).catch(function(error){ this.setState({error:error.response.data.msg}); }.bind(this));
 
    }
    

    render(){

        var valcat = window.setopcategori;
 
        return(

            <React.Fragment>
                

            <div className="page-title black animated fadeIn">
                <div className="page-title-bg editable-alpha" data-alpha="40" style={{opacity:"0.4"}}><img src=" " alt=""/></div>
                <div className="content padded dark">
                    <div className="top-bar">
                        
                        <div className="pull-left">
                            <Link to={'/'} className="btn btn-default btn-pull-up"><i className="icon-before fa fa-chevron-left"></i>Página principal</Link>
                        </div> 
                        
                    </div>
                    <div>
                        <center><h1 className="short">

                        {

                            (this.props.match.params.id === undefined) &&
                            <font>Novo post</font>

                        } 

                        {

                            (this.props.match.params.id !== undefined) &&
                            <font>Editando post</font>

                        } 
                                                    
                        </h1> </center>
                    </div>
                </div>
            </div>

            <div className="row"> 
                <div className="">

 
                    <form id="formPost" style={{margin:"60px 200px 100px 200px"}}>


                        <div>

                            Use esse botão de upload apenas para subir o arquivo e pegar o link:
                            <input id="filea" type="file" style={{margin:'10px 0px'}} />
                            <button style={{height:'11px',padding:'5px 10px 19px 10px'}} type="button" onClick={ () => this.postFile() } class="btn btn-default">Enviar arquivo</button>
                            
                        </div>
                        <br/>
                        
                        {

                            (this.state.file !== "") &&

                            <div class="alert alert-success long" style={{fontSize:'18px'}}>
                                <strong>Dados do arquivo:</strong> {JSON.stringify(this.state.file)}
                            </div>

                        }

                        {

                            (this.state.error !== "") &&

                            <div class="alert alert-danger long">
                                <strong>Ops!</strong> {this.state.error}
                            </div>

                        }
                        
                        <div class="form-group">
                        <label for="exampleInputEmail1">Nome:</label>
                            <input type="text" name="name" id="name" class="form-control" />
                        </div>

                        <div class="form-group">
                        <label for="exampleInputEmail1">Decrição:</label>
                            <textarea type="text" name="description" id="description" class="form-control"></textarea>
                        </div>

                        <div class="form-group">
                        <label for="exampleInputEmail1">HTML:</label>
                            <textarea type="text" name="html" id="html" class="form-control"></textarea>
                        </div>
 

                        <div class="form-group">
                            <label for="exampleInputEmail1">Categoria:</label>
                            <select type="text" defaultValue={valcat} name="categorie" id="categorie" class="form-control">
                                
                            {
            
                                this.props.state.menu.map(function(value, i){ 
                                    
                                     

                                    return(<option key={value.id} value={value.id}>{value.name}</option>) 
                                
                                })

                            } 

                            </select>
                        </div>

                        <div class="form-group">
                        <label for="exampleInputEmail1">Capa: (INFORME O ID)</label>
                            <input type="text" name="cover" id="cover" class="form-control" />
                        </div>

                        <div class="form-group">
                        <label for="exampleInputEmail1">Arquivo: (INFORME O ID)</label>
                            <input type="text" name="file" id="file" class="form-control" />
                        </div>
                        
                    
                        <div class="checkbox">
                        
                        </div>

                        <button type="button" onClick={ () => this.post() } class="btn btn-default">Enviar</button>

                    </form>
                
                </div>  
            </div>
            
        
            </React.Fragment>
            
          );

    }

}


export default Categorie;
