import React, { Component } from 'react';  
import {Link} from "react-router-dom";

class Categorie extends Component {

    /*constructor(props){

        super(props);
    
    }*/
    
    render(){

        return(

            <React.Fragment>
              
                <section id="profile" class="black" style={{height:'548px'}}>
        
                    <div id="profile-bg" class="editable-alpha" data-alpha="40" style={{opacity:'0.4'}}><img src="https://www.tokkoro.com/picsup/2955577-no-mans-sky-video-games-abstract___landscape-nature-wallpapers.jpg" alt="" /></div>
                
                    <div class="content padded dark">
                    
                        <div class="top-bar">
                        
                            <div class="pull-left">
                                <span class="hiddend-sm hidden-xs">Confira nossas mídias sociais!! &nbsp; &nbsp; </span>
                                <ul class="social-icons si-pull-up">
                                    <li><Link href="/"><i class="fa fa-facebook"></i></Link></li>
                                    <li><Link href="/"><i class="fa fa-twitter"></i></Link></li>
                                    <li><Link href="/"><i class="fa fa-google-plus"></i></Link></li>
                                </ul>
                            </div>
                            
                            <div class="pull-right">
                                <Link to="/" class="hidden-xs">cs.plus</Link>
                            </div>
                            
                        </div>
                    
                        <div class="profile-content" style={{marginTop:'-84px'}}>

                            <h1 class="short">Comece a criar jogos </h1>
                            <h5>Acesses nossos <span class="text-primary">assets</span> e <span class="text-primary">tutoriais</span> e crie jogos incríveis.</h5>
                            {/*
                            
                                <a href="" class="btn btn-primary" target="_blank">Buy this theme!</a> <a href="#contact" class="btn btn-default">Contact me</a>
                            */}
                            
                            

                        </div>
                    
                                
                    </div>    	
                    
                </section>
            
            </React.Fragment>
            
          );

    }

}


export default Categorie;
