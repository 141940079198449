import React, { Component } from 'react';

class Categorie extends Component {

    /*constructor(props){

        super(props);
    
    }*/
    
    render(){

        return(

            <React.Fragment>
              
            
        
            </React.Fragment>
            
          );

    }

}


export default Categorie;
