import React from 'react';  
import {Link} from "react-router-dom";
import axios from "axios";
import $ from "jquery";

import moment from "moment";

require('moment/locale/pt-br');
require('moment/locale/en-au');
require('moment/locale/es');

moment.locale('pt-br');


class Categorie extends React.Component {

    constructor(props){

        super(props);
        this.getDateCategorie = this.getDateCategorie.bind(this);

        this.state = {menu:this.props.match.params.id,load:false,posts:[]};
        this.getPost = this.getPost.bind(this);

        this.search = this.search.bind(this);

        window.categorie = this;
        
    }

    componentDidMount(){

        this.getDateCategorie();
        this.getPost();

    }

    componentDidUpdate(){

        if(parseInt(this.props.match.params.id) !== parseInt(this.state.menu)){

            this.setState({menu:this.props.match.params.id});
            this.getPost();
            $(".inputSeach").val('');

        }

    }

    async getPost(){

        await this.setState({load:false});

        // Make a request for a user with a given ID
        await axios.get(window.url+'/categorie/posts/'+this.props.match.params.id).then(function(response){

           this.setState({posts:response.data,load:true});
           //console.log(response);

        }.bind(this)).catch(function(error){ console.log(error); });

    }

    getDateCategorie(){
 
        var index = this.props.state.menu.map(function(x) {return x.id; }).indexOf(parseInt(this.props.match.params.id));
        return this.props.state.menu[index];

    }

    async search(){

        // Make a request for a user with a given ID
        await axios.get(window.url+'/categorie/posts/'+this.props.match.params.id+"?search="+$(".inputSeach").val()).then(function(response){

            this.setState({posts:response.data,load:true});
            //console.log(response);
 
         }.bind(this)).catch(function(error){ console.log(error); });

    }


    async importEngine(data){

        window.parent.postMessage(
            {
                event_id: 'importAsset',
                data: data
            }, 
            "*" //or "www.parentpage.com"
        ); 

    }
    
    render(){

        var menu = this.getDateCategorie();
        
        return (

            <React.Fragment>
              



            <div className="page-title black animated fadeIn">
                <div className="page-title-bg editable-alpha" data-alpha="40" style={{opacity:"0.4"}}><img src={menu.background} alt=""/></div>
                <div className="content padded dark">
                    <div className="top-bar">

                        <div className="pull-left" hidden>
                            <Link to={'/'} className="btn btn-default btn-pull-up"><i className="icon-before fa fa-chevron-left"></i>Página principal</Link>
                        </div>

                        {
                        
                            (window.admin !== undefined) &&
                            <div className="pull-left" style={{marginLeft:"5px"}}>
                                <Link onClick={ () => { window.setopcategori = this.props.match.params.id; } } to={'/newpost'} className="btn btn-default btn-pull-up"><i className="icon-before fa fa-plus"></i>Novo post</Link>
                            </div>

                        }

                        <div className="pull-right">
                            <h6>Você pode usar essa coisas para evoluir mais rápido o seu game.</h6>
                        </div>

                    </div>
                    <div>
                        <h1 className="short">{menu.name}</h1> 
                    </div>
                </div>
            </div>

             

            <div className="content padded" style={{paddingTop:'35px'}}>


            <div>

                <center>    
                    <input onKeyUp={ () => this.search() } id="inputSearch" type="email" className="form-control inputSeach" placeholder="Faça sua pesquisa aqui" />
                </center> 
                
            </div>

            <br/>
            <br/>
                
                {
                    
                    (this.state.load === true) &&
                    <React.Fragment>

                        {
                            
                            this.state.posts.map(function(value, i){ 

                                var cover = "";
                                if(value.data_cover !== undefined){

                                    cover = window.url+value.data_cover.url;

                                }
 
                                return(<article key={'teste_'+value.id} className="post col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 animated fadeIn" style={{height:'420px'}}>
                                            <div className="divarticle">
                                                <center><div className="post-image"><img src={cover} alt=""/></div></center>
                                                <div className="post-content-holder">
                                                    <div className="post-content">
                                                        <div className="post-info clearfix">
                                                            <span className="post-date">{ moment(value.date,'YYYY-MM-DD').format('DD MMM YYYY') } 
                                                            
                                                                <font id="ida1">#{value.id}</font>
                                                            </span>
                                                            <div className="post-meta" hidden>
                                                                <span><i className="fa fa-tag"></i>{/*<a href="#">Webdesign</a>*/}</span>
                                                            </div>
                                                        </div>
                                                        <div className="post-text">

                                                            <p className="lead">{value.name}</p>
                                                            <p>{value.description}</p>
                                                            
                                                            {

                                                                (value.html !== "") &&
                                                                <Link to={'/post/'+value.id} className="btn btn-blue btn-sm"><i style={{color:"#fff"}} className="fa fa-eye"></i> Acessar</Link>
                                                            
                                                            }
                                                            
                                                            {

                                                                (value.file !== "" && value.file !== null) &&
                                                                <a href={window.url+'/download/'+value.data_file.id} style={{marginLeft:"5px"}} type="button" className="btn btn-primary btn-sm"><i style={{color:"#fff"}} className="fa fa-download" aria-hidden="true"></i> Download</a>

                                                            }

                                                            {

                                                                (value.file !== "" && value.file !== null) &&
                                                                <button onClick={ () => { this.importEngine(value); } } style={{backgroundColor:'#1d96ff',marginLeft:"5px"}} type="button" className="btn btn-primary btn-sm"><i style={{color:"#fff"}} className="fa fa-download" aria-hidden="true"></i> Importar</button>

                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>)

                            }.bind(this))

                        }
                        {

                            (this.state.posts.length === 0) &&
                            <React.Fragment>

                                <center><h3 className="fontFundo">Nada foi encontrado.</h3></center>

                            </React.Fragment>

                        }

                    </React.Fragment>

                }


            </div>

           
        
            </React.Fragment>
          );

    }

}


export default Categorie;
