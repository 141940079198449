import React, { Component } from 'react';  
import {Link} from "react-router-dom";

class Header extends Component {

    /*constructor(props){

        super(props);
    
    }*/
    
    render(){

        return (
            <React.Fragment >
              
              
                <header id="nav-content" className="vertical-nav nav-white">
            
                    <div className="me-logo" style={{height:'80px',backgroundImage:'linear-gradient(180deg,#232223 -106%,#d3333b 121%)'}}>
                        <Link to="/">
                            <div className="logo-content" style={{top:'27%'}}>
                            <h4 className="short">Asset <strong>Store</strong></h4>
                            <span className="logo-caption" style={{color:"#fff"}}>and tutorials</span>
                            </div>
                        </Link>
                    </div>
                     
                    <ul className="menu nav me-nav">
        
                        {
        
                            this.props.state.menu.map(function(value, i){ 
                                
                                return(<li className="menu-item" key={value.id}><Link to={'/categorie/'+value.id}><span>{value.name}</span><i className={value.icon}></i></Link></li>) 
                            
                            })
        
                        } 
                    </ul>
                     
                    <span className="copyright hidden-md">© 2019<b> CS Plus</b>.</span>
        
                </header>
        
        
            </React.Fragment>
          );

    }

}


export default Header;
