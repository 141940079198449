import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import * as serviceWorker from './serviceWorker';
import "./Custom.css";

if(window.location.origin === "http://192.168.15.200:3000"){

    window.url = "http://192.168.15.200:3001";

}else{

    window.url = "/api";

}
 

ReactDOM.render(<Router><App/></Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


