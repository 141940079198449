import React, { Component } from 'react';  
import {Route} from "react-router-dom";

import Header from "./pages/includes/Header.jsx";
import axios from "axios";
import Categorie from "./pages/Categorie.jsx";
import Tutorial from "./pages/Tutorial.jsx";
import Home from "./pages/Home.jsx";
import Newpost from "./pages/Newpost.jsx";
import Post from "./pages/Post.jsx";

class App extends Component {

  constructor(props){

    super(props);

    this.getCategories = this.getCategories.bind(this);
    this.state = {menu:[],load:false};

    window.app = this;

  }

  componentDidMount(){

    this.getCategories();

  }

  async getCategories(){

    // Make a request for a user with a given ID
    await axios.get(window.url+'/categorie').then(function(response){

      this.setState({menu:response.data,load:true});

    }.bind(this)).catch(function(error){ console.log(error); });

  }

  render(){
  return (
    <React.Fragment >
      
      <Header state={this.state} />
   
      <div id="page-content">

        {

          (this.state.load === true) &&
          <React.Fragment>
            
            <Route exact path="/" render={ (props) => <Home {...props} state={this.state} /> }  />
            <Route exact path="/categorie/:id/" render={ (props) => <Categorie {...props} state={this.state} /> }  />
            <Route exact path="/tutorial/:id/" render={ (props) => <Tutorial {...props} state={this.state} /> }  />

            <Route exact path="/newpost" render={ (props) => <Newpost {...props} state={this.state} /> }  />
            <Route exact path="/post/:id/" render={ (props) => <Post {...props} state={this.state} /> }  />
            <Route exact path="/editpost/:id/" render={ (props) => <Newpost {...props} state={this.state} /> }  />

          </React.Fragment>

        }
      
      </div>

    </React.Fragment>

  );

  }

}


export default App;
